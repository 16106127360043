import React, { useEffect, useRef, useState } from "react";
import searchIcon from "./../Assets/Records/icons_ic_search.svg";
import { RxCross2 } from "react-icons/rx";

function SearchBar({ setQuery }) {
  const [searchText, setSearchText] = useState("");

  const avoidFirstCall = useRef(true);

  function handleChange(e) {
    setSearchText(e.target.value);
  }

  useEffect(() => {
    const debounce = setTimeout(() => {
      setQuery(searchText);
    }, 2000);

    if (avoidFirstCall.current) {
      clearTimeout(debounce);
    } else {
      avoidFirstCall.current = true;
    }

    return () => {
      avoidFirstCall.current = false;
      clearTimeout(debounce);
    };
  }, [searchText]);

  return (
    <div className="flex border border-cadetGrey rounded-lg px-2 py-2 gap-x-1">
      <div className="text-dark flex items-center">
        <img src={searchIcon} alt="search icon" />
      </div>
      <input
        type="text"
        value={searchText}
        onChange={handleChange}
        placeholder="Search by Patient name, Contact, Record ID or Test name"
        className="w-full bg-transparent outline-none text-dark text-base font-medium"
      />
      {searchText && (
        <div
          className="text-dark flex items-center cursor-pointer"
          onClick={() => {
            setSearchText("");
          }}
        >
          <RxCross2 />
        </div>
      )}
    </div>
  );
}

export default SearchBar;
