const AUTH_TOKEN = "atsgyjnadaE@sdy";
const BASE_URL_STAGING =
  "https://trolley-staging.thankyoubhaiya.com/api/v1/user";

// const config = {
//     prod: {
//         BASE_URL: BASE_URL,
//         AUTH_TOKEN: AUTH_TOKEN,
//         BASE_URL_STAGING: BASE_URL_STAGING,
//     },

//     staging: {
//         BASE_URL: BASE_URL,
//         AUTH_TOKEN: AUTH_TOKEN,
//         BASE_URL_STAGING: BASE_URL_STAGING,
//     },

//     local: {
//         BASE_URL: BASE_URL,
//         AUTH_TOKEN: AUTH_TOKEN,
//         BASE_URL_STAGING: BASE_URL_STAGING,
//     },
// }

export { AUTH_TOKEN, BASE_URL_STAGING };
