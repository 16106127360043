function recordDataValidityCheck(record) {
  if (record.test_name === "") {
    alert("Please enter Test name");
    return false;
  }

  if (record.patient_name === "") {
    alert("Please enter patient name");
    return false;
  }

  if (record.patient_contact === "") {
    alert("Please enter patient contact");
    return false;
  } else if (
    record.patient_contact.length !== 10 ||
    [7, 8, 9].indexOf(parseInt(record.patient_contact[0])) === -1
  ) {
    alert("Please enter a valid 10 digit contact number");
    return false;
  }

  if (record.asset === null) {
    alert("Please add a file");
    return false;
  }

  return true;
}

export default recordDataValidityCheck;
