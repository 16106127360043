import React from "react";
import appIcon from "./../Assets/Icon.png";
import { useAuth } from "../hooks/useAuth";
function Header({ labName }) {
  const {logout} = useAuth();

  function handleClick() {
    logout();
  }

  return (
    <div className="min-h-[100px] flex justify-between items-center px-40 text-blackTwo text-xl shadow-md">
      <div className="flex items-center">
        <img src={appIcon} alt="logo" className="h-20 w-20" />
      </div>
      <div className="text-turquoise text-2xl font-semibold px-6 py-2 bg-paleGrey rounded-lg">
        {labName}
      </div>
      <div className="cursor-pointer" onClick={handleClick}>
        Logout
      </div>
    </div>
  );
}

export default Header;
