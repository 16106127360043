import { useState } from "react";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import uploadIcon from "./../Assets/FileDropzone/icons_ic_cloud_upload.svg";
import { RxCross1 } from "react-icons/rx";

function FileDropzone({ setNewRecord, uploading }) {
  const [loading, setLoading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [file, setFile] = useState(null);

  function handleDrop(files) {
    setLoading(true);

    let assetType = files[0].type;

    if (
      assetType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      assetType === "application/msword"
    ) {
      assetType = "doc";
    } else if (assetType === "image/jpeg" || assetType === "image/png") {
      assetType = "image";
    } else {
      assetType = "pdf";
    }

    setTimeout(() => {
      setLoading(false);
      setFileUploaded(true);
    }, 2000);
    setFile(files[0]);
    setNewRecord((prev) => ({
      ...prev,
      asset: files[0],
      asset_name: files[0].name,
      asset_type: assetType,
    }));
  }

  function handleReject(files) {
    console.log("Rejected files", files);
  }

  function onFileClick() {
    window.open(URL.createObjectURL(file), "_blank");
  }

  function onCross() {
    setFileUploaded(false);
    setNewRecord((prev) => ({
      ...prev,
      asset: null,
      asset_name: "",
      asset_type: "",
    }));
    setFile(null);
  }

  return (
    <>
      <div className="justify-center items-center rounded-xl">
        {fileUploaded ? (
          <>
            <div className="flex border-[1px] items-center w-max p-3 rounded">
              <div
                className="justify-center items-center text-center text-sm text-textPrimary mr-1 cursor-pointer"
                onClick={onFileClick}
              >
                {file.name}
              </div>
              <div
                className="justify-center items-center cursor-pointer"
                onClick={onCross}
              >
                <RxCross1 />
              </div>
            </div>
          </>
        ) : (
          <Dropzone
            onDrop={handleDrop}
            onReject={handleReject}
            loading={loading || uploading}
            accept={[
              MIME_TYPES.pdf,
              MIME_TYPES.doc,
              MIME_TYPES.docx,
              MIME_TYPES.jpeg,
              MIME_TYPES.png,
            ]}
            className="bg-blueyGrey_20_8 p-10 cursor-pointer"
          >
            <img src={uploadIcon} alt="upload icon" className="mx-auto" />
            <div className="text-center text-sm text-rareHappening mt-2">
              Upload file
            </div>
          </Dropzone>
        )}
      </div>
    </>
  );
}

export default FileDropzone;
