import React from "react";
import appIcon from "./../Assets/Icon.png";

function Footer() {
  return (
    <div className="h-[15vh] bg-whiteSmoke flex items-center justify-between px-60 ">
      <div className="flex flex-col gap-y-2">
        <div className="flex-col justify-center items-center ">
          <img src={appIcon} alt="logo" className="h-20 w-20 mx-auto " />
          <div className="w-32 text-dark text-xs text-center">
            © All rights reserved made by{" "}
            <span className="text-turquoise text-xs">twincare</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <a className="block text-black50 underline decoration-black50 underline-offset-2 cursor-pointer">
          Terms and conditions
        </a>
        <a className="block text-turquoise underline decoration-turquoise underline-offset-2 cursor-pointer">
          Contact Twincare
        </a>
      </div>
    </div>
  );
}

export default Footer;
