const storeData = (key, value) => {
  const jsonValue = JSON.stringify(value);
  localStorage.setItem(key, jsonValue);
};

const getData = (key) => {
  const jsonValue = localStorage.getItem(key);
  return jsonValue != null ? JSON.parse(jsonValue) : null;
};

const removeValue = (key) => {
  localStorage.removeItem(key);
  return true;
};

export { storeData, getData, removeValue };
