import React from "react";
import PaginationPerPage from "./PaginationPerPage";
import TextTooltip from "./TextTooltip";
import ArrowIcon from "./../Assets/Records/icons_ic_arrow_down_1.svg";
import formatDate from "./../Utils/formatDate";
import DoneIcon from "./../Assets/icons_ic_check.svg";
import { PiWarningCircleLight } from "react-icons/pi";

function RecordTable({
  recordData,
  page,
  setPage,
  perPage,
  setPerPage,
  sort,
  setSort,
}) {
  function getArrowIcon(sort_order) {
    if (sort_order === "asc") {
      return (
        <img
          src={ArrowIcon}
          alt="arrow"
          style={{ transform: "rotate(180deg)" }}
        />
      );
    } else if (sort_order === "desc") {
      return <img src={ArrowIcon} alt="arrow" />;
    }

    return null;
  }

  function handleClick(event) {
    if (event.target.tagName !== "DIV") return;

    const column = event.target.innerText;

    if (
      [
        "Record ID",
        "Test Name",
        "Patients Name",
        "Patients Contact",
        "Date of Upload",
      ].includes(column)
    ) {
      if (sort.sort_by === column) {
        setSort({
          sort_by: sort.sort_order === "asc" ? sort.sort_by : null,
          sort_order: sort.sort_order === "asc" ? "desc" : null,
        });
      } else {
        setSort({
          sort_by: column,
          sort_order: "asc",
        });
      }
    }
  }

  return (
    <div>
      <div className="bg-paleGrey rounded-xl px-2 pt-6 pb-2">
        <table className="w-full border-separate border-spacing-y-4">
          <thead>
            <tr
              className="text-left text-darkGreyishBlue"
              onClick={handleClick}
            >
              <th className="w-[5%] pl-4 pb-4">S.No</th>
              <th className="w-[11%] pl-4 pb-4 ">
                <div className="flex gap-x-1 cursor-pointer items-center">
                  {sort.sort_by === "Record ID"
                    ? getArrowIcon(sort.sort_order)
                    : null}
                  Record ID
                </div>
              </th>
              <th className="w-[11%] pl-4 pb-4 ">
                <div className="flex gap-x-1 cursor-pointer items-center">
                  {sort.sort_by === "Test Name"
                    ? getArrowIcon(sort.sort_order)
                    : null}
                  Test Name
                </div>
              </th>
              <th className="w-[11%] pl-4 pb-4 ">
                <div className="flex gap-x-1 cursor-pointer items-center">
                  {sort.sort_by === "Patients Name"
                    ? getArrowIcon(sort.sort_order)
                    : null}
                  Patients Name
                </div>
              </th>
              <th className="w-[11%] pl-4 pb-4 ">
                <div className="flex gap-x-1 cursor-pointer items-center">
                  {sort.sort_by === "Patients Contact"
                    ? getArrowIcon(sort.sort_order)
                    : null}
                  Patients Contact
                </div>
              </th>
              <th className="w-[11%] pl-4 pb-4 ">
                <div className="flex gap-x-1 cursor-pointer items-center">
                  {sort.sort_by === "Date of Upload"
                    ? getArrowIcon(sort.sort_order)
                    : null}
                  Date of Upload
                </div>
              </th>
              <th className="w-[11%] pl-4 pb-4 ">SMS Status</th>
              <th className="w-[10%] pl-4 pb-4"></th>
            </tr>
          </thead>
          <tbody>
            {recordData.map((record, index) => (
              <tr key={record.record_id}>
                <td className="text-textPrimary rounded-l-lg bg-white">
                  <div className="w-full pl-4 py-4">
                    {index + 1}
                  </div>
                </td>
                <td className="bg-white text-textPrimary">
                  <div className="w-full pl-4 py-4">
                    {record.record_id}
                  </div>
                </td>
                <td className="bg-white text-textPrimary">
                  <div className="w-full pl-4 py-4">
                    {record.test_name}
                  </div>
                </td>
                <td className="bg-white text-darkGreyishBlue">
                  <div className="w-full pl-4 py-4">
                    {record.patient_name}
                  </div>
                </td>
                <td className="bg-white text-darkGreyishBlue">
                  <div className="w-full pl-4 py-4">
                    {record.patient_contact}
                  </div>
                </td>
                <td className="bg-white text-darkGreyishBlue">
                  <div className="w-full pl-4 py-4">
                    {formatDate(record.date_of_upload)}
                  </div>
                </td>
                <td className="bg-white text-darkGreyishBlue">
                  <div className="w-full pl-4 py-4">
                    <div className="flex gap-x-2 items-center">
                      {record.sms_status === "1" ? (
                        <>
                          <img src={DoneIcon} alt="done" />
                          <span className="text-textSecondary">Sent</span>
                        </>
                      ) : (
                        <>
                          <PiWarningCircleLight size={20} color="#ffab00" />
                          <span className="text-textSecondary">Not Sent</span>
                        </>
                      )}
                    </div>
                  </div>
                </td>
                <td className="font-bold flex-1 justify-center items-center rounded-r-lg bg-white">
                  <div className="w-full pl-4 py-4"
                    onClick={() => {
                      window.open(record.asset_urls[0], "_blank");
                    }}
                  >
                    <TextTooltip text={record.asset_name} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <PaginationPerPage
        total={Math.ceil(recordData.length / perPage)}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </div>
  );
}

export default RecordTable;
