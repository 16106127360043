import React, { useState } from "react";
import Footer from "../Components/Footer";
import { sendOTPAPICall, verifyOTPAPICall } from "../Service/APICalls";
import OverlappingLabelInput from "../Components/OverlappingLabelInput";
import DarkBtn from "../Components/DarkBtn";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

function LoginPage() {

  //Check if user already logged in
  const { user, login } = useAuth();
  
  const [mobileNumber, setMobileNumber] = useState("");

  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);


  if(user){
    return <Navigate to="/" replace={true} />
  }

  async function sendOTP() {
    try {
      const response = await sendOTPAPICall(mobileNumber);
      return response;
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      if (!otpSent) {
        const response = await sendOTP();

        if (response.is_error === 0) {
          setOtpSent(true);
        } else {
          alert("OOPS! Something went wrong. Please try again.");
        }
      } else {
        const response = await verifyOTPAPICall(mobileNumber, otp);

        if (response?.is_error === 1) {
          alert("Invalid OTP");
        } else {
          login(response);
          // storeData("user", response);
          // navigate("/records", {
          //   state: { user: response },
          //   replace: true,
          // });
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function resendOTP() {
    try {
      sendOTP();
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <div className="flex flex-col h-screen ">
      <div className="h-[90vh] flex">
        {/* LeftPart */}
        <div className="flex flex-col justify-center items-center gap-20 w-2/3 opacity-90">
          <div className="max-w-44 text-center text-dark text-2xl font-bold">
            Hi, Welcome to Twincare
          </div>

          <div>
            <img
              className="max-h-[540px] max-w-[720px]"
              src={require("../Assets/LoginPage/drawable-hdpi/illustrations_illustration_dashboard.png")}
              srcSet={`
          ${require("../Assets/LoginPage/drawable-hdpi/illustrations_illustration_dashboard.png")} 1x, 
          ${require("../Assets/LoginPage/drawable-mdpi/illustrations_illustration_dashboard.png")} 1.5x, 
          ${require("../Assets/LoginPage/drawable-xhdpi/illustrations_illustration_dashboard.png")} 2x,
          ${require("../Assets/LoginPage/drawable-xxhdpi/illustrations_illustration_dashboard.png")} 3x,
          ${require("../Assets/LoginPage/drawable-xxxhdpi/illustrations_illustration_dashboard.png")} 4x,
          `}
              alt="illustration_dashboard"
            />
          </div>
        </div>

        {/* RightPart */}
        <div className="flex flex-col justify-center items-stretch w-1/3 px-16 pb-24 gap-10">
          <div className="text-dark text-xl font-bold">Sign in to Twincare</div>

          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-5">
              <OverlappingLabelInput
                label={"Mobile"}
                variable={mobileNumber}
                setVariable={setMobileNumber}
                length={10}
              />
              {otpSent ? (
                <>
                  <OverlappingLabelInput
                    label={"OTP"}
                    variable={otp}
                    setVariable={setOtp}
                    length={6}
                  />
                  <div className="text-darkGreyishBlue text-xs">
                    Didn't get the OTP?{" "}
                    <span
                      className="text-turquoise underline decoration-turquoise underline-offset-2 cursor-pointer"
                      onClick={resendOTP}
                    >
                      Resend
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}

              <DarkBtn
                title={otpSent ? "Verify and Proceed" : "Send OTP"}
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LoginPage;
