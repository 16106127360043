import React from "react";
import chart_sparkline from "./../Assets/Records/chart_sparkline.svg";

function InformationCard({ label, value, image }) {
  let iconURL = image;

  if (iconURL === "" || iconURL === null || iconURL === undefined) {
    iconURL = chart_sparkline;
  }

  return (
    <div className="w-[33%] flex justify-between items-center px-6 py-6 rounded-xl shadow-md bg-white">
      {/* left */}
      <div className="font-semibold text-dark">
        <div className="text-sm">{label}</div>
        <div className="text-4xl">{value}</div>
      </div>

      {/* right */}
      <div>{<img src={iconURL} alt="icon" />}</div>
    </div>
  );
}

export default InformationCard;
