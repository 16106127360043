import React, { useEffect, useRef, useState } from "react";
import InformationCard from "../Components/InformationCard";
import AddRecordBtn from "../Components/AddRecordBtn";
import SearchBar from "../Components/SearchBar";
import RecordTable from "../Components/RecordTable";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import DateRangeSelector from "../Components/DateRangeSelector";
import noRecords from "./../Assets/Records/no_records.svg";
import { fetchRecords, getWebMetrics } from "../Service/APICalls";
import formatDate from "../Utils/formatDate";
import { useAuth } from "../hooks/useAuth";
function Records() {

  const { user } = useAuth();
  
  const [records, setRecords] = useState([]);
  const [cards, setCards] = useState([
    {
      label: "Total Records",
      value: 28,
      image: "",
    },
    {
      label: "Records (last 30 days)",
      value: 32,
      image: "",
    },
    {
      label: "Total Patients",
      value: 5,
      image: "",
    },
  ]);
  const [query, setQuery] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [perPage, setPerPage] = useState("5");
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort_by: null,
    sort_order: null,
  });

  const dateRangePickerRef = useRef();

  async function getRecords() {
    try {
      const recordResponse = await fetchRecords(
        sort.sort_by,
        sort.sort_order,
        query,
        dateRange[0],
        dateRange[1],
        page,
        perPage
      );
      setRecords(recordResponse);
      console.log("recordResponse :", recordResponse);
      const webMetricsResponse = await getWebMetrics();
      // setCards(webMetricsResponse);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  // const successMessageModalRef = useRef();
  // const errorMessageModalRef = useRef();

  useEffect(() => {
    getRecords();
  }, [sort, page, perPage, query, dateRange]);

  return (
    <>
      <Header labName={user.lab_name} />
      <div
        className="h-[75vh] 2xl:px-60 lg:px-30 md:px-20 sm:px-10 pb-11 overflow-scroll scroll"
        style={{ scrollbarWidth: "none" }}
      >
        <div className="flex justify-between items-center gap-x-6 pt-10">
          {cards.map((card, index) => (
            <InformationCard
              key={index}
              label={card.label}
              value={card.value}
              image={card.image}
            />
          ))}
        </div>

        <div className="flex justify-between items-center mt-10">
          <span className="text-2xl font-bold text-dark">Records</span>
          <AddRecordBtn setRecords={setRecords} />
        </div>

        <div className="flex justify-between mt-4 ">
          <div className="w-[70%] lg:w-[75%] 2xl:w-[80%] ">
            <SearchBar setQuery={setQuery} />
          </div>
          <div className=" bg-paleGrey w-[29%] lg:w-[24%] 2xl:w-[19%] rounded-lg cursor-pointer">
            <DateRangeSelector
              ref={dateRangePickerRef}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
            {
              <div
                className="flex justify-center items-center h-10"
                onClick={() => {
                  console.log("Called");
                  dateRangePickerRef.current.toggleRangePicker();
                }}
              >
                <span className="text-dark text-sm font-bold">
                  {formatDate(dateRange.startDate)} -{" "}
                  {formatDate(dateRange.endDate)}
                </span>
              </div>
            }
          </div>
        </div>
        <div className=" mt-10">
          {records.length === 0 ? (
            <div className="flex flex-col justify-center items-center bg-whiteSmoke py-5">
              <img src={noRecords} alt="No Records" />
              <div className="text-textPrimary text-2xl font-semibold">
                No records found
              </div>
              <div className="text-textPrimary text-lg ">
                Add records using the add record button
              </div>
            </div>
          ) : (
            <RecordTable
              recordData={records}
              page={page}
              setPage={setPage}
              perPage={perPage}
              setPerPage={setPerPage}
              sort={sort}
              setSort={setSort}
            />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Records;
