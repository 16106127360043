import React from "react";
import { useState } from "react";
import { Modal, Group } from "@mantine/core";
import AddRecordPage from "./AddRecordPage";
function AddRecordBtn({setRecords}) {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={<div className="text-lg font-bold ">Add Record</div>}
        radius={16}
        size="lg"
        centered
      >
        <AddRecordPage setRecords={setRecords} setOpened={setOpened} />
      </Modal>

      <Group position="center">
        <button
          className="bg-turquoise px-[22px] py-[11px] rounded-lg"
          onClick={() => setOpened(true)}
        >
          <div className="text-lg text-whiteTwo font-bold">
            <span className="text-xl">+</span> Add Record
          </div>
        </button>
      </Group>
    </>
  );
}

export default AddRecordBtn;
