import { getData } from "./storage";

const getToken = () => {
  try {
    const user = getData("user");

    return user.token;
  } catch (error) {
    console.error("Error:", error);
  }
};

const getObject = () => {
  try {
    const user = getData("user");
    return user;
  } catch (error) {
    console.error("Error:", error);
  }
};

export { getToken, getObject };
