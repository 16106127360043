import React from "react";
import { Pagination } from "@mantine/core";
import { Select } from "@mantine/core";

function PaginationPerPage({ total, page, setPage, perPage, setPerPage }) {
  return (
    <div className="flex justify-between items-center mt-6">
      <div className="flex items-center">
        <div className="text-sm text-dark">Records per page :</div>
        <Select
          data={["5", "10", "15", "20", "25", "30", "35", "40", "45", "50"]}
          defaultValue={perPage}
          value={perPage}
          placeholder="Select records per page"
          className="text-sm text-textPrimary w-20 ml-2"
          onChange={(value) => {
            setPerPage(value);
            setPage(1);
          }}
        />
      </div>
      <Pagination
        total={total}
        color={"#00bbad"}
        className="text-sm text-textPrimary"
        value={page}
        onChange={(value) => {
          setPage(value);
        }}
      />
    </div>
  );
}

export default PaginationPerPage;
