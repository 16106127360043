import React from "react";

function DarkBtn({ title, type }) {
  return (
    <button
      className="bg-dark text-whiteTwo w-full py-3 rounded-lg"
      type={type}
    >
      {title}
    </button>
  );
}

export default DarkBtn;
