import React, { useState } from "react";
import FileDropzone from "./FileDropzone";
import { getToken } from "../Utils/getSession";
import { postRecord } from "../Service/APICalls";
import recordDataValidityCheck from "../Utils/recordDataValidityCheck";

function AddRecordPage({ setRecords, setOpened }) {
  const [newRecord, setNewRecord] = useState({
    test_name: "", //Done
    patient_name: "", //Done
    patient_contact: "", //Done
    date_of_upload: new Date().toISOString().split("T")[0], //Done
    record_type: "", //NotDone
    asset_type: "", //Done
    asset_name: "", //Done
    description: "", //NotDone
    uploaded_by: getToken(), //Done
    asset: null, //Done
    sms_status: 0, //NotDone
  });

  const [uploading, setUploading] = useState(false);

  function handleChange(variable, value) {
    setNewRecord((prev) => ({
      ...prev,
      [variable]: value,
    }));
  }

  async function addRecord(data) {
    try {
      setUploading(true);
      const response = await postRecord(data);
      setRecords((prev) => [...prev, response]);
      setUploading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function handleClick(event) {
    event.preventDefault();

    if (event.target.textContent === "Cancel") {
      setOpened(false);
      return;
    }

    if (!recordDataValidityCheck(newRecord)) {
      return;
    }

    await addRecord(newRecord);

    if (event.target.textContent === "Save & send SMS") {
      // send sms
    }

    setOpened(false);
  }

  return (
    <form className="flex flex-col gap-y-6 pt-2">
      <div>
        <input
          type="text"
          placeholder="*Test Name"
          className="w-full p-2 rounded-lg border border-cadetGrey "
          required
          onChange={(e) => {
            handleChange("test_name", e.target.value);
          }}
          value={newRecord.test_name}
        />
      </div>
      <div>
        <input
          type="text"
          placeholder="*Patients Name"
          className="w-full mt-2 p-2 rounded-lg border border-cadetGrey "
          required
          onChange={(e) => {
            handleChange("patient_name", e.target.value);
          }}
          value={newRecord.patient_name}
        />
      </div>
      <div>
        <input
          type="text"
          placeholder="*Patients Contact"
          className="w-full mt-2 p-2 rounded-lg border border-cadetGrey "
          required
          onChange={(e) => {
            handleChange("patient_contact", e.target.value);
          }}
          value={newRecord.patient_contact}
        />
      </div>
      <div>
        <FileDropzone setNewRecord={setNewRecord} uploading={uploading} />
      </div>

      <div
        className="flex justify-end items-center gap-x-3"
        onClick={handleClick}
      >
        <button type="button" className="text-dark text-sm font-bold ">
          Cancel
        </button>
        <button
          type="submit"
          className="border text-sm border-turquoise text-turquoise px-4 py-2 rounded-lg font-bold"
        >
          Save
        </button>
        <button
          type="submit"
          className="bg-turquoise text-sm text-white px-4 py-2 rounded-lg font-bold"
        >
          Save & send SMS
        </button>
      </div>
    </form>
  );
}

export default AddRecordPage;
