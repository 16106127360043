import React from "react";
import { Button, Tooltip } from "@mantine/core";

function TextTooltip({ text }) {
  return (
    <Tooltip
      wrapLines
      color="white"
      width={220}
      withArrow
      transition="fade"
      transitionDuration={200}
      label={text}
      c={"rgba(0, 0, 0, 0.5)"}
    >
      <button className="bg-brightTeal text-turquoise text-sm px-3 py-1 rounded-lg cursor-pointer hover:bg-turquoise hover:text-white">
        View File
      </button>
    </Tooltip>
  );
}

export default TextTooltip;
