import React from "react";

function OverlappingLabelInput({ label, length, variable, setVariable }) {
  return (
    <div className="relative">
      <input
        type="number"
        value={variable}
        onChange={(e) => {
          setVariable(e.target.value);
        }}
        minLength={length}
        maxLength={length}
        id="mobile_input"
        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-dark rounded-lg border-2 border-cadetGrey focus:outline-none focus:ring-0 focus:border-cadetGrey peer"
        placeholder=""
      />
      <label
        htmlFor="mobile_input"
        className="absolute text-sm text-cadetGrey duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
      >
        {label}
      </label>
    </div>
  );
}

export default OverlappingLabelInput;
