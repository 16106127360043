import React, { useState, useImperativeHandle, forwardRef } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { Modal } from "@mantine/core";

function DateRangeSelector(prop, ref) {
  const [opened, setOpened] = useState(false);
  const [selectionRange, setselectionRange] = useState({
    ...prop.dateRange,
    key: "selection",
  });

  function handleSelect(ranges) {
    setselectionRange(ranges.selection);

    prop.setDateRange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    });
  }

  useImperativeHandle(ref, () => ({
    toggleRangePicker: () => setOpened((curr) => !curr),
  }));

  return (
    <>
      <Modal
        size={"auto"}
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
        centered
      >
        <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
      </Modal>
    </>
  );
}

export default forwardRef(DateRangeSelector);
