import { AUTH_TOKEN, BASE_URL_STAGING } from "./Config";
import { getToken } from "../Utils/getSession";

async function fetchRecords(
  sortBy,
  sortOrder,
  query,
  startDate,
  endDate,
  page,
  perPage
) {
  let params = {
    sort_by: sortBy,
    sort_order: sortOrder,
    query: query,
    start_date: startDate,
    end_date: endDate,
    page_number: page,
    page_size: perPage,
  };

  const keys = Object.keys(params);

  for (let i = 0; i < keys.length; i++) {
    if (
      params[keys[i]] === null ||
      params[keys[i]] === undefined ||
      params[keys[i]] === ""
    ) {
      delete params[keys[i]];
    }
  }

  try {
    const queryParams = new URLSearchParams(params);

    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${AUTH_TOKEN}`,
        token: getToken(),
      },
    };

    const data = await fetch(
      `${BASE_URL_STAGING}/labrecords?${queryParams}`,
      options
    ).then((response) => response.json());

    return data.records;
  } catch (error) {
    console.log(error);
  }
}

async function postRecord(data) {
  try {
    const formData = new FormData();

    const keys = Object.keys(data);

    for (let i = 0; i < keys.length; i++) {
      formData.append(keys[i], data[keys[i]]);
    }

    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${AUTH_TOKEN}`,
        token: getToken(),
      },
      body: formData,
    };

    const response = await fetch(
      `${BASE_URL_STAGING}/labrecords/`,
      options
    ).then((response) => {
      return response.json();
    });

    return response;
  } catch (error) {
    console.log(error);
  }
}

async function sendOTPAPICall(phoneNumber) {
  try {
    const formData = new FormData();
    formData.append("phone_number", phoneNumber);

    const data = await fetch(`${BASE_URL_STAGING}/lablogin/`, {
      method: "POST",
      body: formData,
    }).then((res) => res.json());

    return data;
  } catch (error) {
    console.log(error);
  }
}

async function verifyOTPAPICall(phone_number, otp) {
  try {
    const formData = new FormData();
    formData.append("phone_number", phone_number);
    formData.append("otp", otp);

    const userData = await fetch(`${BASE_URL_STAGING}/labverify/`, {
      method: "POST",
      body: formData,
    }).then((res) => res.json());

    return userData;
  } catch (error) {
    console.log("Error :", error);
  }
}

async function getWebMetrics() {
  try {
    const data = await fetch(`${BASE_URL_STAGING}/labsummary/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${AUTH_TOKEN}`,
        token: getToken(),
      },
    }).then((response) => response.json());
    return data;
  } catch (error) {
    console.log("Error:", error);
  }
}

export {
  fetchRecords,
  postRecord,
  sendOTPAPICall,
  verifyOTPAPICall,
  getWebMetrics,
};
