import "./App.css";
import React from 'react'
import {
  Route,
  Routes,
} from "react-router-dom";
import { createTheme, MantineProvider } from "@mantine/core";
import LoginPage from "./Pages/LoginPage";
import Records from "./Pages/Records";
import { AuthProvider } from "./hooks/useAuth";
import ProtectedRoute from "./Components/ProtectedRoute";

function App() {
  return (
    <MantineProvider theme={createTheme()}>
      <AuthProvider>
        <Routes>
          <Route path="/" element=
          { 
            <ProtectedRoute>
              <Records />
            </ProtectedRoute>
          } />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </AuthProvider>
    </MantineProvider>
  )
}

export default App;
